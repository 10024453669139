import React, { useEffect } from "react"

export default function useSafeWindowEventlistener(
  event: string,
  func: () => void,
  dependencies?: any[]
) {
  useEffect(
    () => {
      if (typeof window !== "undefined") {
        const previewIframe =
          document.querySelector<HTMLIFrameElement>("#previewWindow")
        if (previewIframe && previewIframe.contentWindow) {
          previewIframe.contentWindow.addEventListener(event, func)
        } else {
          window.addEventListener(event, func)
        }
      }
      return () => {
        if (typeof window !== "undefined") {
          const previewIframe =
            document.querySelector<HTMLIFrameElement>("#previewWindow")
          if (previewIframe && previewIframe.contentWindow) {
            previewIframe.contentWindow.removeEventListener(event, func)
          } else {
            window.removeEventListener(event, func)
          }
        }
      }
    },
    dependencies ? dependencies : []
  )
}
